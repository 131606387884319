import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "welcome-to-sonoland",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#welcome-to-sonoland",
        "aria-label": "welcome to sonoland permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Welcome to sono.land!`}</h1>
    <p><strong parentName="p">{`sono`}</strong>{` is a lightweight module for Deno🦖 that enables real-time bidirectional communication between clients by using native WebSocket and WebRTC APIs.
Our module abstracts away the low-level implementation of `}<a parentName="p" {...{
        "href": "https://deno.land/std@0.95.0/ws"
      }}>{`ws`}</a>{` from the Deno Standard Library.
By importing our module, developers can build their own telecommunications app that lets users communicate with low latency and high reliability.
Take your time in reading this documentation to get started! If you have any questions, please reach out to the team!`}</p>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`🤝 WebRTC for P2P connections`}</li>
      <li parentName="ul">{`📸 Video and 🎤 Audio implementation`}</li>
      <li parentName="ul">{`👨‍👩‍👧‍👦Exhibit Many to Many WebRTC connectivity utilizing the Mesh system`}</li>
      <li parentName="ul">{`🔌 WebSocket for clients to server connections`}</li>
      <li parentName="ul">{`💬 Chatroom demonstration to display WebSocket connections`}</li>
      <li parentName="ul">{`📺 Multiple channels for different discussions`}</li>
      <li parentName="ul">{`📣 Broadcast messages to everyone in the channel`}</li>
      <li parentName="ul">{`📫 Direct message to a single client`}</li>
      <li parentName="ul">{`📝 Grab a list of all clients in a specified channel`}</li>
      <li parentName="ul">{`🔥 and much more`}</li>
    </ul>
    <br />
    <p><a parentName="p" {...{
        "href": "/getting-started"
      }}>{`Get started now!`}</a></p>
    <p>{`Check out our repo `}<a parentName="p" {...{
        "href": "https://github.com/oslabs-beta/sono.land"
      }}>{`here`}</a>{` and start importing the `}<strong parentName="p">{`sono`}</strong>{` modules from the Deno Third Party Modules `}<a parentName="p" {...{
        "href": "https://deno.land/x/sono@v1.1"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      